.rotate {
  &-45 {
    transform: rotate(45deg);
  }
  &-90 {
    transform: rotate(90deg);
  }
  &-135 {
    transform: rotate(135deg);
  }
  &-180 {
    transform: rotate(180deg);
  }
  &-225 {
    transform: rotate(225deg);
  }
  &-270 {
    transform: rotate(270deg);
  }
  &-315 {
    transform: rotate(315deg);
  }
}
