@import url('https://use.typekit.net/kpk5yma.css');
@import '../abstracts/variables.scss';
* {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

html, body {
	font-size: 16px; //Root Font Size and Default Web Size
}

body {
	@extend %default_font-family;
}

h1 {
  // mobile
  font-size: 3rem;
  line-height: 56px;
  letter-spacing: .86px;
  font-weight: 700;
  @include media-breakpoint-up(lg) {
    //desktop
    font-size: 4.5rem;
    letter-spacing: 72px;
    letter-spacing: 1px;
  }
}

h2 {
  // mobile
  font-size: 2.25rem;
  font-weight: 700;
  font-stretch: normal;
  line-height: 1.17;
  letter-spacing: .45px;
  @include media-breakpoint-up(lg) {
    // desktop
    font-size: 2.5rem;
    letter-spacing: .5px;
  }
}

h3 {
  // mobile
  font-size: 2rem;
  line-height: 40px;
  letter-spacing: .5px;
  font-weight: 700;
  @include media-breakpoint-up(lg) {
    // desktop
    font-size: 2.25rem;
  }
}

h4 {
  //mobile
  font-size: 1rem;
  line-height: 30px;
  letter-spacing: 1px;
  font-weight: 700;
  text-transform: uppercase;
  @include media-breakpoint-up(lg) {
    //desktop
    font-size: 1.125rem;
  }
}

h5 {
  // mobile and desktop
  font-size: 1.125rem;
  line-height: 20px;
  letter-spacing: .4px;
  font-weight: 700;
}

p {
  font-size: 1.125rem;
  line-height: 1.67;
  letter-spacing: .4px;
  &.small {
    font-size: .938rem;
    line-height: 24px;
    letter-spacing: .3px;
  }
}
ul,ol{
  font-size: 1.125rem;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.67;
  letter-spacing: .4px;
  li{
  font-size: 1.125rem;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.67;
  letter-spacing: .4px;
  }
}

a {
  color: $primary;
  text-decoration: none;
  &:hover {
    color: darken($primary, 5%);
  }
}