@import "../../stylesheets/abstracts/variables.scss";

.pixel-nexusconnect-otterbox-2023 {
  --bs-body-bg: var(--bs-white);
  .billboard {
    padding-top: 2rem;
    padding-bottom: 1rem;
    background-color: #EDEDED;
    h1 {
      color: #000;
      text-align: center;
      font-family: Helvetica Neue;
      font-size: 1.875rem;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }
    &__brands {
      color: #747272;
      text-align: center;
      font-family: Helvetica Neue;
      font-size: 1.125rem;
      font-style: normal;
      font-weight: 400;
      line-height: normal;

      .brand {
        border-right-width: 1px;
        border-right-style: solid;
        border-right-color: var(--bs-primary);

        &:last-child {
          border-right-width: 0;
        }
      }
    }
    &__hero {
      width: 100%;
      max-width: 30.375rem;
      max-height: 17.8125rem;
    }
   
    &__logos .logo {
      &__google,
      &__ipcmobile {
        width: 5.75rem;
      }
      &__otterbox {
        width: 1.75rem;
      }
    }
  }
  .features {
    padding-top: 1.25rem;
    margin-bottom: 2.5rem;
    .fluid-container {
      max-width: 37.5rem;
    }
    &__image {
      border-radius: 1rem;
      overflow: hidden;
      width: 100%;
      max-width: 16.75rem;
      margin-bottom: .75rem;
    }
    p {
      width: 100%;
      color: #000;
      text-align: center;
      font-family: Helvetica Neue;
      font-size: 0.75rem;
      font-style: normal;
      line-height: normal;
    }
  }
  .value-props {
    margin-bottom: 2.5rem;
    h2 {
      color: #000;
      text-align: center;
      font-family: Helvetica Neue;
      font-size: 1.25rem;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      margin-bottom: 2rem;
    }
    &__cards {
      max-width: 37.5rem;
      .cards {
        background-color: #f1f1f1;
        &__card-1 { 
          .card__image {
            width: 2rem;
          }
          }
        &__card-2 { 
          .card__image {
            width: 2.25rem;
          }
        }
        &__card-3 {
          .card__image {
            width: 1.31rem;
          }
        }
      }
      p {
        color: #000;
        text-align: center;
        font-family: Helvetica Neue;
        font-size: 0.625rem;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
      }
    }
  }
  .cta {
    margin-bottom: 3rem;
    &__text {
      color: #000;
      text-align: center;
      font-family: Helvetica Neue;
      font-size: 0.75rem;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
    &__headline {
      color: #65D007;
      text-align: center;
      font-family: Helvetica Neue;
      font-size: 2.25rem;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }
    &__link {
      margin-top: 2.5rem;
      width: 10.5rem;
      height: 2.25rem;
      flex-shrink: 0;
      border-radius: 1rem;
      span {
        color: var(--bs-white);
        text-align: justify;
        font-family: Helvetica Neue;
        font-size: 1.25rem;
        font-style: normal;
        font-weight: 400;
        line-height: 1;
      }
    }
  }
  footer {
    padding-bottom: 1rem;
    .fluid-container {
      max-width: 37.5rem;
    }
    .trademarks {
      color: #979595;
      font-family: Helvetica Neue;
      font-size: 0.625rem;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
    p > ol li {
      color: #979595;
      font-family: Helvetica Neue;
      font-size: 0.375rem;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }
}