* {
  box-sizing: border-box;
}
*:focus {
  outline: none;
}
html,
body {
  position: relative;
  height: 100%;
}

#cookieBanner {
  position: fixed;
  width: 94%;
  left: 3%;
  bottom: -310px;
  padding: 20px;
  background-color: #ffffff;
  color: $secondary;
  border-radius: .25rem;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3);
  transition: bottom 1s ease-in-out;
  z-index: 9999;
  @include media-breakpoint-up(lg) {
    max-width: 300px;
    left: 20px;
    right: auto;
    bottom: -270px;
  }
  p {
    line-height: 1.2;
  }
  button {
    color: #ffffff;
  }
  .btn {
    margin-bottom: 0;
  }
  &.show {
    bottom: 10px;
    @include media-breakpoint-up(lg) {
      bottom: 20px;
    }
  }
}