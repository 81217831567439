@import "../../stylesheets/abstracts/variables.scss";

$maxWidth: 450px;
body {
  --bs-body-bg: #E5E5E5
}
.digital-card {
  max-width: $maxWidth;
  background-color: #FFFFFF;
  margin: 0 auto;
  &__header {
    position: relative;
    height: 210px;
    overflow: hidden;
    &-content {
      padding: 0 1rem;
      z-index: 1;
    }
  }
  &__banner {
    top: 0;
    left: 0;
    height: 150px;
    overflow: hidden;
    img  {
      animation: zoomEffect 15s ease-in-out infinite alternate;
    }
  }
  &__body {
    padding: 0 1rem;
  }
  &__footer {
    width: 100%;
    max-width: $maxWidth;
    bottom: 0;
    padding: .5rem 1rem;
    background-color: #F2F2F2;

    &-cta-btn {
      width: 45%;
      text-align: center;
    }
  }
  &__profileImg-container {
   width: 110px;
   height: 110px;
   background-color: #FFFFFF;
   overflow: hidden;
   img {
    width: 100%;
   }
  }
  &__fullname {
    font-size: 2rem;
    letter-spacing: -1px;
  }
  &__position {
    font-size: 1rem;
    margin-top: -0.5rem;
  }
  &__v-card {
    img {
      width: 1rem;
    }
  }
  &__social {
    width: 49%;
    img {
      width: 25px;
      height: 25px;
      margin-right: .5rem;
    }
  }
  .bg-green {
    background-color: $primary;
  }.bg-blue {
    background-color: $blue;
  }
}
@keyframes zoomEffect {
  from {
    transform: scale(1)
  }

  to {
    transform: scale(1.3)
  }
}